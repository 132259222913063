.starting {
  display: flex;
  position: relative;
  justify-content: center;
  align-content: center;
  text-align: center;
  height: 100vh;
}

.logo {
  margin: auto;
  align-self: center;
}
